import './src/sass/style.scss'
import React from 'react'
import { initDynamicRS } from './src/components/shared/dynamic-rs'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

const activeLang = process.env.LOCALE ? process.env.LOCALE : `en`

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lf4fIIhAAAAAJIoBeBNIDiuNSuX6E0XyjGrjQ7S">
      {element}
    </GoogleReCaptchaProvider>
  )
}

export const onClientEntry = () => {
  return initDynamicRS(activeLang, `PuxDesignGatsby`)
}

export const onInitialClientRender = () => {
  setTimeout(SetRecaptchaVisibility, 1000)
}

export const onRouteUpdate = () => {
  SetRecaptchaVisibility()
}

function SetRecaptchaVisibility() {
  const form = document.querySelector('form')
  const recaptcha = document.querySelector('.grecaptcha-badge')

  if (form && recaptcha) {
    recaptcha.style.visibility = "visible";
  } else if (!form && recaptcha) {
    recaptcha.style.visibility = "hidden";
  }
}