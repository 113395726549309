class DynamicRS {
  locale: string
  project: string
  file: PO_JSONType
  resStrings: translationsItemType

  constructor(locale: string, project: string) {
    this.locale = locale
    this.project = project
    this.file = require(`../../../i18n/${locale}.po.json`)
    this.resStrings = this.file.translations[``]
  }

  getResourceString(key: string): string {
    const translation = this.resStrings[key]?.msgstr[0]

    if (translation) {
      return translation
    } else {
      return key
    }
  }
}

interface PO_JSONType {
  charset: string
  headers: headersType
  translations: translationsType
}

interface translationsType {
  [key: string]: translationsItemType
}

interface translationsItemType {
  [key: string]: {
    msgid: string
    msgstr: [string]
  }
}

interface headersType {
  'content-transfer-encoding': string
  'content-type': string
  language: string
  'mime-version': string
  'plural-forms': string
}

let dynamicRSinstance: DynamicRS | null = null

export function initDynamicRS(locale: string, project: string): void {
  dynamicRSinstance = new DynamicRS(locale, project)
}

export function dt(key: string): string {
  if (dynamicRSinstance !== null) {
    return dynamicRSinstance.getResourceString(key)
  } else {
    return key
  }
}
